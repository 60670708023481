<template>
  <div class="main">
    <router-view/>
  </div>
</template>


<script>
export default {
  name: "App",
};
</script>


<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  overflow-x: hidden;
}
body {
  margin: 0;
  overflow-x: hidden;
}
</style>