import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vuexy from "vuexy";
import Vuetify from "vuetify";

import VueLazyLoad from "vue3-lazyload";
import {
  faBars,
  faXmark,
  faArrowRightLong,
  faArrowRight,
  faArrowLeft,
  faEnvelope,
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

/* add icons to the library */
library.add(
  faBars,
  faXmark,
  faLinkedin,
  faFacebook,
  faInstagram,
  faYoutube,
  faArrowRightLong,
  faArrowRight,
  faArrowLeft,
  faEnvelope,
  faChevronDown,
  faChevronUp
);

createApp(App)
  .use(router)
  .use(VueLazyLoad)
  // .use(DOMPurify)

  .use(Vuexy)
  .use(Vuetify)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
